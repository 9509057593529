.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height as needed to center vertically within the viewport */
  }
  
/* Full-page overlay styles */
.full-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  z-index: 999; /* Set a high z-index to ensure it's above other content */
}
